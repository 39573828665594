export default abstract class SearchFilterBase {
    protected constructor(recordCount?: number) {
        if (recordCount) this.recordCount = recordCount;
    }

    public recordCount = 1000;

    public hydrate(val: Partial<SearchFilterBase>) {
        Object.keys(val)
            .filter((x) => val.hasOwnProperty(x) && (val as any)[x] !== undefined && (val as any)[x] !== null)
            .forEach((x) => (this as any)[x] = (val as any)[x]);
    }
}
